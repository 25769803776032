import { ClientJS } from 'clientjs';

import '../scss/app.scss';

async function getIpInfo() {
    const response = await fetch('https://ipapi.co/json/');
    return response.json();
}

(async () => {
    const client = new ClientJS();
    const geoIpInfo = await getIpInfo();
    const form = document.getElementById('registerForm');
    const country = form.querySelector('input[name="country"]');
    const phonePrefix = form.querySelector('input[name="prefix"]');
    const phone = form.querySelector('input[name="phone"]');
    const phonePrefixIcon = form.querySelector('.prefix-icon');

    phonePrefixIcon.innerHTML = `<img src="https://raw.githubusercontent.com/lipis/flag-icons/4390ff1d78e8bc2706305eaaec861ff26afdc8c1/flags/1x1/${geoIpInfo.country_code.toLowerCase()}.svg" alt="">`;
    phonePrefix.value = geoIpInfo.country_calling_code;
    country.value = geoIpInfo.country_code;

    phone.onkeypress = (e) => !!/\d/.test(String.fromCharCode(e.keyCode));

    form.addEventListener('submit', async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const submitButton = form.querySelector('button[type="submit"]');

        data.append('fingerprint', client.getFingerprint());
        data.append('action', 'register');

        submitButton.textContent = 'Loading...';
        submitButton.disabled = true;

        const response = await fetch(my_ajax_object.ajax_url, {
            method: 'POST',
            body: data,
        });
        const responseJson = await response.json();

        submitButton.textContent = 'Create Your Free Account';
        submitButton.disabled = false;

        if (response.ok) {
            location.href = responseJson.data.RedirectTo;
        } else {
            alert(responseJson.data);
        }
    });
})();